<template>
  <div>
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>Filters</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <!-- Search -->
          <b-col cols="12" :md="wl_code == 1 ? '4' : '4'">
            <div>
              <b-form-group label="Booking Id" label-for="mc-select-date">
                <validation-provider
                  #default="{ errors }"
                  name="refund amount"
                  rules="noSpecial"
                >
                  <b-form-input
                    v-model="booking_id"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </b-col>
          <b-col cols="12" :md="wl_code == 1 ? '4' : '4'">
            <b-form-group label="Creation Date" label-for="mc-select-date">
              <date-picker
                v-model="bookingRange"
                style="width: 100%"
                type="date"
                range
                format="DD.MM.YYYY"
                placeholder="Select date range"
                :disabled-date="disabledRange"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" :md="wl_code == 1 ? '4' : '4'">
            <b-form-group label="Travel Date" label-for="mc-select-date">
              <date-picker
                v-model="travelRange"
                style="width: 100%"
                type="date"
                range
                format="DD.MM.YYYY"
                placeholder="Select date range"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="4" class="mb-md-1 mb-2">
            <label>Booking Status</label>
            <v-select
              v-model="status"
              :options="BookingStatusEnum"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="w-100"
              :reduce="(val) => val.value"
              :clearable="true"
            />
          </b-col>

          <b-col cols="12" md="4" class="mb-md-1 mb-2">
            <label>Payment Type</label>
            <v-select
              v-model="payment_via"
              :options="PaymentTypeEnum"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="w-100"
              :reduce="(val) => val.value"
              :clearable="true"
            />
          </b-col>

          <b-col cols="12" md="4" class="mb-md-1 mb-2">
            <label>Module</label>
            <v-select
              v-model="module_id"
              :options="tripTypeFilter2"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="w-100"
              :reduce="(val) => val.value"
              :clearable="true"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="4" class="mb-md-1 mb-2">
            <label>Agency</label>
            <v-select
              v-model="customer_type"
              :options="AgencyEnum"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="w-100"
              :reduce="(val) => val.value"
              :clearable="true"
            />
          </b-col>

          <b-col cols="12" md="4">
            <div>
              <b-form-group label="Email" label-for="mc-select-date">
                <b-form-input
                  v-model="email"
                  class="d-inline-block mr-1"
                  placeholder="Email"
                />
              </b-form-group>
            </div>
          </b-col>

          <b-col v-if="wl_code == 1" cols="12" md="4" class="mb-md-1 mb-2">
            <label>Booking Mode</label>
            <v-select
              v-model="booking_mode"
              :options="BookingModeEnum"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="w-100"
              :reduce="(val) => val.value"
              :clearable="true"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="wl_code == 1" cols="12" md="4">
            <label>Filter by WhiteLabel</label>
            <v-select
              v-model="wlCode"
              :options="whitelabelList"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="w-100"
              :reduce="(val) => val.value"
              placeholder="Select Whitelabel"
              :clearable="true"
            />
          </b-col>

          <b-col cols="12" :md="wl_code == 1 ? '4' : '4'">
            <div>
              <b-form-group
                label="Module Supplier Id"
                label-for="mc-select-date"
              >
                <validation-provider
                  #default="{ errors }"
                  name="refund amount"
                  rules="noSpecial"
                >
                  <b-form-input
                    v-model="module_supplier_id"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </b-col>
          <b-col cols="12" md="4" class="demo-inline-spacing">
            <b-button
              variant="primary"
              class="float-right"
              @click="resetFilter"
            >
              Reset
            </b-button>
            <b-button
              class="float-right"
              variant="primary"
              type="Apply"
              @click="applyFilter"
            >
              Apply
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
          <b-col class="d-flex justify-content-end" cols="12" md="6">
            <b-button
              v-if="
                wl_code == 1 &&
                (checkLoginRole() === RoleEnum.SuperAdmin
                  ? true
                  : checkAccess.Edit)
              "
              variant="primary"
              @click="createNewBooking"
            >
              Create new Booking
            </b-button>
            <!-- <export-csv :data="exportResults" name="Bookinglist.csv"> -->
            <!-- <template> -->
            <b-button
              class="ml-1"
              variant="secondary"
              :disabled="exportDisabled"
              @click="showExportModal()"
            >
              <span
                class="cursor-pointer"
                v-b-tooltip.hover.left="
                  'Apply creation or travel date range filter to export booking listing'
                "
              >
                Export
                <feather-icon icon="InfoIcon" size="16" />
              </span>
            </b-button>
            <!-- </template> -->
            <!-- </export-csv> -->
          </b-col>
        </b-row>
      </div>

      <b-table
        v-if="!contentLoading"
        ref="refUserListTable"
        class="position-relative offer_table_cstm"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- <template #empty="scope">
          <div class="my-2">
            {{ scope.emptyText }}
          </div>
        </template> -->
        <template #cell(booking_id)="data">
          <div style="width: 100px">
            <!-- <span
              v-if="
                checkLoginRole() === RoleEnum.SuperAdmin
                  ? true
                  : checkAccess.view
              "
              style="cursor: pointer; font-weight: 600"
              @click="
                navigateToBookingDetail(
                  data.item.id,
                  data.item.bookings ? data.item.bookings[0].bookingMode : '',
                  data.item.wlCode
                )
              "
              >{{
                data.item.moduleInfo1
                  ? data.item.moduleInfo1.localBookingId
                  : ""
              }}
            </span> -->
            <span
              v-if="
                checkLoginRole() === RoleEnum.SuperAdmin
                  ? true
                  : checkAccess.view
              "
            >
              <b-link
                v-if="data.item.bookings[0].bookingMode == 2"
                style="cursor: pointer; font-weight: 600"
                :to="{
                  name: 'new-booking-details',
                  params: { id: data.item.id },
                }"
                >{{
                  data.item.moduleInfo1
                    ? data.item.moduleInfo1.localBookingId
                    : ""
                }}
              </b-link>
              <b-link
                v-else
                style="cursor: pointer; font-weight: 600"
                :to="{
                  name: 'booking-details',
                  params: { id: data.item.id },
                  query: {
                    page: currentPage,
                    rows: perPage,
                    wl_code: data.item.wlCode,
                  },
                }"
                >{{
                  data.item.moduleInfo1
                    ? data.item.moduleInfo1.localBookingId
                    : ""
                }}
              </b-link>
            </span>
            <span v-else>
              <strong>{{
                data.item.moduleInfo1
                  ? data.item.moduleInfo1.localBookingId
                  : ""
              }}</strong>
            </span>
          </div>
        </template>

        <template #cell(quote_id)="data">
          <strong>
            {{ data.item.quoteId ? data.item.quoteId : "-" }}
          </strong>
        </template>

        <template #cell(supplier_id)="data">
          <strong>
            {{
              data.item.moduleInfo1 && data.item.moduleInfo1.supplierBookingId
                ? data.item.moduleInfo1.supplierBookingId
                : "-"
            }}
          </strong>
        </template>

        <template #cell(creation_date)="data">
          <b-badge pill class="text-capitalize">
            <span v-if="data.item.moduleInfo1">
              {{ data.item.moduleInfo1.bookingDateTime | formatDate }}
            </span>
            <span v-else> - </span>
          </b-badge>
        </template>

        <template #cell(status)="data">
          <b-badge
            v-if="
              checkLoginRole() === RoleEnum.SuperAdmin && wl_code == 1
                ? true
                : checkAccess.Edit && wl_code == 1
            "
            style="cursor: pointer"
            pill
            :variant="`light-${resolveMainBookingStatusVariant(
              data.item.bookingStatus ? data.item.bookingStatus : ''
            )}`"
            @click="
              openStatusModal(
                data.item.bookingStatus ? data.item.bookingStatus : '',
                data.item.id,
                data.item.moduleInfo1.supplierBookingId,
                data.item.cancellationReason,
                data.item.bookingStatus &&
                  data.item.bookingStatus === 'Confirmed'
                  ? true
                  : false
              )
            "
          >
            {{ data.item.bookingStatus ? data.item.bookingStatus : "" }}
          </b-badge>
          <b-badge
            v-else
            pill
            :variant="`light-${resolveMainBookingStatusVariant(
              data.item.bookingStatus ? data.item.bookingStatus : ''
            )}`"
          >
            {{ data.item.bookingStatus ? data.item.bookingStatus : "" }}
          </b-badge>
        </template>

        <template #cell(remark)="data">
          <div style="width: 100px">
            {{ data.item.remarks ? data.item.remarks.remarks : "-" }}
          </div>
        </template>

        <template #cell(type)="data">
          <div class="text-nowrap">
            <feather-icon icon="HomeIcon" size="18" class="mr-50" />
            <span class="align-text-top text-capitalize"
              >{{
                data.item.moduleInfo1
                  ? data.item.moduleInfo1.moduleId
                    ? TripType(data.item.moduleInfo1.moduleId)
                    : "-"
                  : "-"
              }}
            </span>
          </div>
        </template>

        <template #cell(checkin-checkout_date)="data">
          <div style="width: 200px">
            <b-badge pill class="text-capitalize">
              <span v-if="data.item.moduleInfo1">
                {{
                  data.item.moduleInfo1.searchCriteria.checkin_date | formatDate
                }}
              </span>
              <span v-else> - </span>
            </b-badge>
            {{
              data.item.moduleInfo1 &&
              data.item.moduleInfo1.moduleId === 2 &&
              data.item.moduleInfo1.searchCriteria.trip_type !== "oneway"
                ? "-"
                : ""
            }}
            <b-badge
              v-if="
                !(
                  data.item.moduleInfo1 &&
                  data.item.moduleInfo1.moduleId === 2 &&
                  data.item.moduleInfo1.searchCriteria.trip_type === 'oneway'
                )
              "
              pill
              class="text-capitalize"
            >
              <span v-if="data.item.moduleInfo1">
                {{
                  data.item.moduleInfo1 && data.item.moduleInfo1.moduleId !== 2
                    ? data.item.moduleInfo1.searchCriteria.checkout_date
                    : data.item.moduleInfo1.moduleId === 2 &&
                      data.item.moduleInfo1.searchCriteria.trip_type !==
                        "roundtrip"
                    ? data.item.moduleInfo1.searchCriteria.checkin_date
                    : data.item.moduleInfo1.searchCriteria.checkout_date
                      | formatDate
                }}
              </span>
              <span v-else> - </span>
            </b-badge>
          </div>
        </template>

        <template #cell(destination)="data">
          <div class="text-nowrap">
            <feather-icon icon="MapPinIcon" size="18" class="mr-50" />
            <span class="align-text-top text-capitalize"
              >{{
                data.item.moduleInfo1 &&
                data.item.moduleInfo1.searchCriteria &&
                data.item.moduleInfo1.searchCriteria.value
                  ? data.item.moduleInfo1.searchCriteria.value
                  : "-"
              }}
            </span>
          </div>
        </template>

        <template #cell(travellers)="data">
          <div
            v-b-tooltip.hover.bottom="
              `Adult: ${data.item.travellerAgeCount.adult} | Child: ${
                data.item.travellerAgeCount.child
              } ${
                data.item.moduleInfo1 && data.item.moduleInfo1.moduleId === 2
                  ? '| Infant: ' + data.item.travellerAgeCount.child
                  : ''
              }`
            "
          >
            {{ data.item.bookingTravellers.length }}
          </div>
        </template>

        <template #cell(billing_name)="data">
          {{
            data.item.billingInfo.billing_name
              ? data.item.billingInfo.billing_name
              : "-"
          }}
        </template>

        <template #cell(postal_code)="data">
          {{
            data.item.billingInfo.postal_code
              ? data.item.billingInfo.postal_code
              : "-"
          }}
        </template>

        <template #cell(country)="data">
          <div class="text-nowrap">
            <feather-icon icon="GlobeIcon" size="18" class="mr-50" />
            <span class="align-text-top text-capitalize">
              {{ data.item.billingInfo.country }}
            </span>
          </div>
        </template>

        <template #cell(city)="data">
          <div class="text-nowrap">
            <feather-icon icon="GlobeIcon" size="18" class="mr-50" />
            <span class="align-text-top text-capitalize">
              {{ data.item.billingInfo.city }}
            </span>
          </div>
        </template>

        <template #cell(address)="data">
          <div style="width: 150px">
            {{ data.item.billingInfo.address1 }}
            {{
              data.item.billingInfo.address2
                ? data.item.billingInfo.address2
                : ""
            }}
          </div>
        </template>

        <template #cell(total)="data">
          <div style="width: 100px">
            {{ Number(data.item.totalBookingAmount) }}
            {{ data.item.currencySymbol }}
          </div>
        </template>

        <template #cell(discount)="data">
          <div
            v-b-tooltip.hover.bottom="
              data.item.moduleInfo1 ? data.item.moduleInfo1.promoCode : '-'
            "
          >
            {{
              data.item.moduleInfo1
                ? data.item.moduleInfo1.discount
                  ? Math.abs(data.item.moduleInfo1.discount)
                  : "0"
                : "-"
            }}
            {{ data.item.currencySymbol }}
          </div>
        </template>

        <template #cell(retail_price)="data">
          <div style="width: 100px">
            {{ data.item.retailPrice }}
            {{ data.item.currencySymbol }}
          </div>
        </template>

        <template #cell(first_installment)="data">
          <div style="width: 100px">
            {{
              data.item.moduleInfo1 &&
              data.item.moduleInfo1.bookingInstallments.length
                ? data.item.moduleInfo1.bookingInstallments[0].amount
                : "-"
            }}
            {{
              data.item.moduleInfo1 &&
              data.item.moduleInfo1.bookingInstallments.length
                ? data.item.currencySymbol
                : ""
            }}
          </div>
        </template>

        <template #cell(second_installment)="data">
          <div style="width: 100px">
            {{
              data.item.moduleInfo1 &&
              data.item.moduleInfo1.bookingInstallments[1]
                ? data.item.moduleInfo1.bookingInstallments[1].amount
                : "-"
            }}
            {{
              data.item.moduleInfo1 &&
              data.item.moduleInfo1.bookingInstallments[1]
                ? data.item.currencySymbol
                : ""
            }}
          </div>
        </template>

        <template #cell(second_installment_deadline)="data">
          <b-badge
            v-if="
              data.item.moduleInfo1.bookingInstallments[1] &&
              data.item.moduleInfo1.bookingInstallments[1].installmentDate
            "
            pill
            class="text-capitalize"
          >
            <span
              v-if="
                data.item.moduleInfo1 &&
                data.item.moduleInfo1.bookingInstallments[1]
              "
            >
              {{
                moment(
                  data.item.moduleInfo1.bookingInstallments[1].installmentDate
                ).format("DD-MM-YYYY")
              }}
            </span>
          </b-badge>
          <span v-else> - </span>
        </template>

        <template #cell(second_installment_payment_date)="data">
          <b-badge
            v-if="
              data.item.moduleInfo1.bookingInstallments[1] &&
              data.item.moduleInfo1.bookingInstallments[1].paymentDate
            "
            pill
            class="text-capitalize"
          >
            <span
              v-if="
                data.item.moduleInfo1 &&
                data.item.moduleInfo1.bookingInstallments[1]
              "
            ></span>
            {{
              moment(
                data.item.moduleInfo1.bookingInstallments[1].paymentDate
              ).format("DD-MM-YYYY")
            }}
          </b-badge>
          <span v-else> - </span>
        </template>

        <template #cell(provider_cost)="data">
          <div style="width: 100px">
            {{ data.item.providerCost }}
            {{
              data.item.moduleInfo1
                ? data.item.moduleInfo1.providerCost
                  ? data.item.currencySymbol
                  : ""
                : "-"
            }}
          </div>
        </template>

        <template #cell(agency)="data">
          {{
            data.item.moduleInfo1
              ? data.item.moduleInfo1.agency
                ? AgencyStatus(data.item.moduleInfo1.agency)
                : "-"
              : "-"
          }}
        </template>

        <template #cell(payment_type)="data">
          <div class="text-nowrap">
            <feather-icon icon="CreditCardIcon" size="18" class="mr-50" />
            <span class="align-text-top text-capitalize">
              {{
                data.item.moduleInfo1
                  ? data.item.moduleInfo1.paymentVia
                    ? PaymentType(data.item.moduleInfo1.paymentVia)
                    : "-"
                  : "-"
              }}
            </span>
          </div>
        </template>

        <template #cell(contact_person)="data">
          <div style="width: 200px">
            <div
              v-b-tooltip.hover.bottom="
                data.item.user_details ? data.item.user_details.email : '-'
              "
            >
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="32"
                    :src="data.item.avatar"
                    :text="
                      avatarText(
                        data.item.user_details
                          ? data.item.user_details.firstName
                          : '-'
                      )
                    "
                    :variant="`light-success`"
                  />
                </template>
                {{
                  data.item.user_details
                    ? data.item.user_details.firstName
                    : "-"
                }}
                {{
                  data.item.user_details ? data.item.user_details.lastName : "-"
                }}
              </b-media>
            </div>
          </div>
        </template>

        <template #cell(white_label)="data">
          {{
            data.item.wlCode && wl_code == 1
              ? getWhiteLabelName(data.item.wlCode)
              : "-"
          }}
        </template>

        <template #cell(currency)="data">
          {{
            data.item.currency_details ? data.item.currency_details.code : "-"
          }}
        </template>

        <template #cell(refund)="data">
          <b-form @submit.prevent>
            <div style="display: flex; width: 120px">
              <validation-provider
                #default="{ errors }"
                name="refund amount"
                rules="required|integer"
              >
                <b-form-input
                  v-model="data.item.refund"
                  placeholder="Refund"
                  :disabled="
                    !(checkLoginRole() === RoleEnum.SuperAdmin && wl_code == 1
                      ? true
                      : checkAccess.Edit && wl_code == 1)
                  "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <feather-icon
                style="cursor: pointer"
                size="30"
                icon="SaveIcon"
                v-if="
                  checkLoginRole() === RoleEnum.SuperAdmin && wl_code == 1
                    ? true
                    : checkAccess.Edit && wl_code == 1
                "
                @click="
                  saveRefund(data.item.id, data.item.refund, data.item.wlCode)
                "
              />
            </div>
          </b-form>
        </template>

        <template #cell(vat)="data">
          {{
            data.item.billingInfo.vat_number
              ? data.item.billingInfo.vat_number
              : "-"
          }}
        </template>

        <template #cell(pending)="data">
          <div style="width: 100px">
            {{ data.item.pendingAmount }}
            {{ data.item.currencySymbol }}
          </div>
        </template>

        <template #cell(extras_services)="data">
          <div style="width: 200px">
            <b-badge
              class="ml-1"
              v-if="data.item.extrasInitials.find((e) => e === 'I')"
              :variant="
                extrasInitialVariant(
                  data.item.extras.find((e) => e.type === 'Insurance').status
                )
              "
            >
              {{ data.item.extrasInitials.find((e) => e === "I") }}
            </b-badge>

            <b-badge
              class="ml-1"
              :variant="transferInitialVariant(data.item)"
              v-if="data.item.extrasInitials.find((e) => e === 'T')"
            >
              {{ data.item.extrasInitials.find((e) => e === "T") }}
            </b-badge>

            <b-badge
              class="ml-1"
              :variant="
                extrasInitialVariant(
                  data.item.baggageBoarding.find(
                    (e) => e.comment === 'Priority'
                  ).status
                )
              "
              v-if="data.item.extrasInitials.find((e) => e === 'P')"
            >
              {{ data.item.extrasInitials.find((e) => e === "P") }}
            </b-badge>

            <b-badge
              class="ml-1"
              v-if="data.item.extrasInitials.find((e) => e === 'B')"
              :variant="
                extrasInitialVariant(
                  data.item.baggageBoarding.find((e) => e.comment === '20kg')
                    .status
                )
              "
            >
              {{ data.item.extrasInitials.find((e) => e === "B") }}
            </b-badge>
          </div>
        </template>

        <template #cell(email)="data">
          {{ data.item.user_details.email }}
        </template>

        <template #cell(hotel_markup)="data">
          <div style="width: 100px">
            {{ data.item.markupAmount }}
            {{ data.item.currencySymbol }}
          </div>
        </template>

        <template #cell(flight_markup)="data">
          <div style="width: 100px">
            {{ data.item.flightMarkup }}
            {{ data.item.flightMarkup ? data.item.currencySymbol : "" }}
          </div>
        </template>

        <template #cell(origin)="data">
          {{
            data.item.flightModuleInfo &&
            data.item.flightModuleInfo.searchCriteria &&
            data.item.flightModuleInfo.searchCriteria.origin
              ? data.item.flightModuleInfo.searchCriteria.origin.value
              : "-"
          }}
        </template>
      </b-table>
      <Loader v-if="contentLoading" />
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!-- <span class="text-muted">Showing  of {{ totalUsers }} entries</span> -->
            <span class="text-muted"
              >Showing
              {{ totalBookings >= 1 ? startIndex + 1 : startIndex }}
              to
              {{ endIndex > totalBookings ? totalBookings : endIndex }}
              of {{ totalBookings }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="totalBookings > 0 && perPage"
              :total-rows="totalBookings"
              :per-page="perPage"
              v-model="currentPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <form-modal
      ref="formModal"
      :is-loaded="isLoaded"
      :status-details="statusDetails"
      @on-submit="changeBookingStatus"
    />
    <b-modal ref="export-modal" hide-footer title="Export Bookings List">
      <div>
        <div class="py-1">
          <div
            v-if="exportResults.length < 1 && exportContentLoading"
            class="spinner-border spinner-border-sm"
          ></div>
          <label for="Loading...">
            {{
              exportResults.length > 0
                ? "Loaded"
                : exportContentLoading
                ? "Loading..."
                : !(exportResults.length > 0)
                ? "No data found"
                : "Loaded"
            }}
          </label>
          <div class="float-right">{{ progressExport }} %</div>
          <b-progress
            :value="progressExport"
            max="100"
            class="progress-bar-success"
            variant="success"
          />
        </div>
        <div class="d-flex justify-content-between modal-footer p-0">
          <export-csv :data="exportResults" name="Bookinglist.csv">
            <template>
              <b-button
                variant="secondary"
                :disabled="exportResults.length < 1 && progressExport < 100"
                @click="hideExportModal()"
              >
                <span>
                  <feather-icon icon="DownloadIcon" size="16" />
                  Download File
                </span>
              </b-button>
            </template>
          </export-csv>
          <b-button class="ml-1" variant="danger" @click="hideExportModal()">
            Cancel
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardHeader,
  BCardBody,
  BFormInput,
  BFormGroup,
  BTable,
  BPagination,
  BBadge,
  VBTooltip,
  BForm,
  BButton,
  BMedia,
  BAvatar,
  BModal,
  BProgress,
  BLink,
} from "bootstrap-vue";
import vSelect from "vue-select";
import DatePicker from "vue2-datepicker";
import { avatarText } from "@core/utils/filter";
import "vue2-datepicker/index.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import { ValidationProvider } from "vee-validate";
import {
  perPageOptions,
  perPage,
  currentPage,
  resConditionCheck,
  BookingStatus,
  resolveBookingStatusVariant,
  AgencyStatus,
  TripType,
  PaymentType,
  WhiteLabel,
  BookingStatusEnum,
  PaymentTypeEnum,
  AgencyEnum,
  tripTypeFilter,
  BookingModeEnum,
  resolveMainBookingStatusVariant,
  extrasInitialVariant,
  RoleEnum,
} from "@/helpers/constant";
import bookingService from "@/services/booking/booking.service";
import whitelabelService from "@/services/whitelabel/whitelabel.service";
import errorResponseHandler from "@/services/errorHandler";
// import refundModal from '@/views/modal/FormModal.vue'
import {
  required,
  integer,
  noSpecial,
} from "@/@core/utils/validations/validations";
import FormModal from "@/views/modal/FormModal.vue";
import Loader from "@/layouts/skeloton-loader/Loader.vue";
import axios from "axios";
import checkLoginRole from "@/helpers/checkLoginRole";
import accessRightCheck from "@/helpers/accessRightCheck";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    vSelect,
    BCardHeader,
    BCardBody,
    BFormInput,
    DatePicker,
    BFormGroup,
    BTable,
    BPagination,
    BBadge,
    BForm,
    ValidationProvider,
    FormModal,
    BButton,
    Loader,
    BMedia,
    BAvatar,
    BModal,
    BProgress,
    BLink,
  },

  directives: {
    "b-tooltip": VBTooltip,
  },

  data() {
    return {
      contentLoading: true,
      exportContentLoading: false,
      perPageOptions,
      perPage,
      currentPage,
      items: [],
      startIndex: 0,
      endIndex: 0,
      totalBookings: 0,
      isSortDirDesc: true,
      sortBy: "",
      BookingStatus,
      resolveBookingStatusVariant,
      resolveMainBookingStatusVariant,
      extrasInitialVariant,
      AgencyStatus,
      TripType,
      PaymentType,
      WhiteLabel,
      avatarText,
      BookingModeEnum,
      tripTypeFilter,
      AgencyEnum,
      PaymentTypeEnum,
      BookingStatusEnum,
      required,
      integer,
      tableColumns: [
        { key: "booking_id" },
        { key: "quote_id" },
        { key: "supplier_id" },
        { key: "creation_date" },
        { key: "status" },
        { key: "type" },
        { key: "remark" },
        { key: "contact_person" },
        { key: "extras_services" },
        { key: "email" },
        { key: "white_label" },
        { key: "agency" },
        { key: "checkin-checkout_date" },
        { key: "origin" },
        { key: "destination" },
        { key: "travellers" },
        { key: "currency" },
        { key: "payment_type" },
        { key: "retail_price" },
        { key: "provider_cost" },
        { key: "hotel_markup" },
        { key: "flight_markup" },
        { key: "discount" },
        { key: "total" },
        { key: "first_installment" },
        { key: "second_installment" },
        { key: "second_installment_deadline" },
        { key: "second_installment_payment_date" },
        { key: "pending" },
        { key: "refund" },
        { key: "billing_name" },
        { key: "postal_code" },
        { key: "country" },
        { key: "city" },
        { key: "address" },
        { key: "vat" },
      ],
      status: "",
      payment_via: "",
      customer_type: "",
      module_id: "",
      booking_id: "",
      email: "",
      bookingRange: "",
      booking_start_date: null,
      booking_end_date: null,
      travelRange: "",
      travel_start_date: "",
      travel_end_date: "",
      statusDetails: {},
      booking_mode: "",
      isLoaded: false,
      extrasAmount: "",
      pendingAmount: "",
      noSpecial,
      exportResults: [],
      extrasInitials: [],
      wl_code: axios.defaults.headers.wl_code,
      whitelabelList: [],
      wlCode: "",
      RoleEnum,
      checkLoginRole,
      checkAccess: {},
      module_supplier_id: "",
      progressExport: 10,
      tripTypeFilter2: tripTypeFilter,
    };
  },

  watch: {
    perPage: {
      handler(val) {
        // this.currentPage = 1;
        // this.getExportData();
        const searchData = JSON.parse(localStorage.getItem("searchData"));
        if (searchData) {
          searchData.no_of_result = val; // updating the status property
          this.currentPage = searchData.page;
          this.wlCode = searchData.wlCode;
          localStorage.setItem("searchData", JSON.stringify(searchData)); // updating the searchData in localStorage
        }
        this.$router
          .push({
            name: "booking-list",
            query: { page: this.currentPage, rows: val, wl_code: this.wlCode },
          })
          .catch(() => {});
        this.getBookingListing();
      },
    },
    currentPage: {
      handler(val) {
        // this.getExportData();
        const searchData = JSON.parse(localStorage.getItem("searchData"));
        if (searchData) {
          searchData.page = val; // updating the status property
          this.perPage = searchData.no_of_result;
          this.wlCode = searchData.wlCode;
          localStorage.setItem("searchData", JSON.stringify(searchData)); // updating the searchData in localStorage
        }
        this.$router
          .push({
            name: "booking-list",
            query: { page: val, rows: this.perPage, wl_code: this.wlCode },
          })
          .catch(() => {});
        this.getBookingListing();
      },
    },

    // status: {
    //   handler() {
    //     this.currentPage = 1;
    //     // this.getBookingListing();
    //     // this.getExportData();
    //   },
    // },
    // payment_via: {
    //   handler() {
    //     this.currentPage = 1;
    //     // this.getBookingListing();
    //     // this.getExportData();
    //   },
    // },
    // customer_type: {
    //   handler() {
    //     this.currentPage = 1;
    //     // this.getBookingListing();
    //     // this.getExportData();
    //   },
    // },
    // module_id: {
    //   handler() {
    //     this.currentPage = 1;
    //     // this.getBookingListing();
    //     // this.getExportData();
    //   },
    // },
    // booking_id: {
    //   handler() {
    //     this.currentPage = 1;
    //     // this.getBookingListing();
    //     // this.getExportData();
    //   },
    // },
    // email: {
    //   handler() {
    //     this.currentPage = 1;
    //     // this.getBookingListing();
    //     // this.getExportData();
    //   },
    // },
    bookingRange: {
      handler(newValue) {
        this.booking_start_date = newValue[0]
          ? moment(newValue[0]).format("YYYY-MM-DD")
          : "";
        this.booking_end_date = newValue[1]
          ? moment(newValue[1]).format("YYYY-MM-DD")
          : "";
        const searchData = JSON.parse(localStorage.getItem("searchData"));
        if (searchData) {
          searchData.bookingRange = newValue ? newValue : "";
          searchData.booking_start_date = this.booking_start_date;
          searchData.booking_end_date = this.booking_end_date;
          localStorage.setItem("searchData", JSON.stringify(searchData));
        }
        // this.getBookingListing();
        // this.getExportData();
      },
    },
    travelRange: {
      handler(newValue) {
        this.travel_start_date = newValue[0]
          ? moment(newValue[0]).format("YYYY-MM-DD")
          : "";
        this.travel_end_date = newValue[1]
          ? moment(newValue[1]).format("YYYY-MM-DD")
          : "";
        const searchData = JSON.parse(localStorage.getItem("searchData"));
        if (searchData) {
          searchData.travelRange = newValue ? newValue : "";
          searchData.travel_start_date = this.travel_start_date;
          searchData.travel_end_date = this.travel_end_date;
          localStorage.setItem("searchData", JSON.stringify(searchData));
        }
        // this.getBookingListing();
        // this.getExportData();
      },
    },
    // booking_mode: {
    //   handler() {
    //     this.currentPage = 1;
    //     // this.getBookingListing();
    //     // this.getExportData();
    //   },
    // },
    wlCode: {
      async handler(val) {
        // this.wlCode = val ? this.wlCode : "all";
        const searchData = JSON.parse(localStorage.getItem("searchData"));
        if (searchData) {
          searchData.wlCode = val;
          this.currentPage = searchData.page;
          this.perPage = searchData.no_of_result;
          localStorage.setItem("searchData", JSON.stringify(searchData)); // updating the searchData in localStorage
        }
        this.$router
          .push({
            name: "booking-list",
            query: { page: this.currentPage, rows: this.perPage, wl_code: val },
          })
          .catch(() => {});
        // this.getBookingListing();
      },
    },
    // module_supplier_id: {
    //   handler() {
    //     this.currentPage = 1;
    //     // this.getBookingListing();
    //     // this.getExportData();
    //   },
    // },
  },

  computed: {
    exportDisabled() {
      if (
        (this.bookingRange.length && this.bookingRange[0]) ||
        (this.travelRange.length && this.travelRange[0])
      ) {
        return !(this.totalBookings > 0) ? true : false;
      } else {
        return true;
      }
    },
  },

  async mounted() {
    this.checkAccess = accessRightCheck(this.$route.meta.module.name);
    const searchData = JSON.parse(localStorage.getItem("searchData"));
    if (searchData) {
      this.perPage = searchData.no_of_result;
      this.currentPage = searchData.page;
      this.isSortDirDesc = searchData.order === "DESC" ? true : false;
      this.sortBy = searchData.order_by;
      this.status = searchData.status;
      this.payment_via = searchData.payment_via;
      this.customer_type = searchData.customer_type;
      this.module_id = searchData.module_id;
      this.booking_id = searchData.booking_id;
      this.email = searchData.email;
      this.bookingRange = searchData.bookingRange
        ? [
            new Date(searchData.bookingRange[0]),
            new Date(searchData.bookingRange[1]),
          ]
        : "";
      this.travelRange = searchData.travelRange
        ? [
            new Date(searchData.travelRange[0]),
            new Date(searchData.travelRange[1]),
          ]
        : "";
      this.booking_start_date = searchData.booking_start_date;
      this.booking_end_date = searchData.booking_end_date;
      this.travel_start_date = searchData.travel_start_date;
      this.travel_end_date = searchData.travel_end_date;
      this.booking_mode = searchData.booking_mode;
      this.module_supplier_id = searchData.module_supplier_id;
    }
    if (this.$route.query) {
      this.currentPage = this.$route.query.page ? this.$route.query.page : 1;
      this.perPage = this.$route.query.rows ? this.$route.query.rows : 10;
      this.wlCode = this.$route.query.wl_code
        ? this.$route.query.wl_code
        : "all";
    }
    await this.getBookingListing();
    if (this.wl_code == 1) {
      await this.getWhitelabelList();
    }
    await this.getWhitelabelDetails();
    // this.getExportData();
  },

  methods: {
    async getBookingListing() {
      const WLCODE =
        localStorage.getItem("signinWLAdmin") &&
        localStorage.getItem("whitelabel_code")
          ? localStorage.getItem("whitelabel_code")
          : this.wl_code !== 1
          ? this.wl_code
          : this.wlCode;
      this.contentLoading = true;
      const payload = {
        no_of_result: this.perPage,
        page: this.currentPage,
        order: this.isSortDirDesc === true ? "DESC" : "ASC",
        order_by: this.sortBy,
        status: this.status,
        payment_via: this.payment_via,
        customer_type: this.customer_type,
        module_id: this.module_id,
        booking_id: this.booking_id,
        email: this.email,
        booking_start_date: this.booking_start_date
          ? this.booking_start_date
          : "",
        booking_end_date: this.booking_end_date ? this.booking_end_date : "",
        travel_start_date: this.travel_start_date,
        travel_end_date: this.travel_end_date,
        booking_mode: this.booking_mode,
        module_supplier_id: this.module_supplier_id,
        all_whitelabel: this.wlCode === "all" ? true : false,
      };

      try {
        const res = await bookingService.getBookingListing(payload, WLCODE);
        if (resConditionCheck(res.status) && res.data.data) {
          // console.log("booking response", res.data.data);
          this.contentLoading = false;
          this.items = res.data.data.map((e) => {
            if (
              e.bookings[0]?.moduleId === 1 ||
              e.bookings[0]?.moduleId === 3
            ) {
              e.moduleInfo1 = e.bookings.find(
                (z) => z.moduleInfo.type === "Hotel"
              );
              e.flightModuleInfo = e.bookings.find(
                (z) => z.moduleInfo.type === "Flight"
              );
              e.pendingAmount = e.moduleInfo1?.bookingInstallments
                ? this.getPendingAmount(e.moduleInfo1.bookingInstallments)
                : "0";
              e.retailPrice = e.moduleInfo1
                ? this.calculateRetailPrice(
                    e.moduleInfo1,
                    e.extras,
                    e.flightModuleInfo
                  )
                : "0";
              e.totalBookingAmount = e.retailPrice
                ? this.calculateTotalBookingAmount(
                    e.retailPrice,
                    e.extras,
                    e.baggageBoarding,
                    e.transfers
                  )
                : "0";
              e.currencySymbol = e.currency_details?.symbol
                ? e.currency_details?.symbol
                : "";
              e.markupAmount =
                e.moduleInfo1?.moduleInfo?.rooms && e.moduleInfo1?.providerCost
                  ? this.calculateMarkup(
                      e.moduleInfo1?.moduleInfo?.rooms?.[0]?.markup,
                      e.moduleInfo1?.providerCost
                    )
                  : "0";
              e.providerCost = e.moduleInfo1
                ? this.calculateProviderCost(e.moduleInfo1, e.flightModuleInfo)
                : "0";
              e.flightMarkup = e.flightModuleInfo
                ? this.calculateFlightMarkup(e.flightModuleInfo)
                : "0";
              e.extrasInitials = this.checkExtrasInitials(e);
            } else if (e.bookings[0]?.moduleId === 2) {
              e.moduleInfo1 = e.bookings.find(
                (z) => z.moduleInfo.type === "Flight"
              );
              e.moduleInfo1.searchCriteria.value =
                e.moduleInfo1.searchCriteria.destination.value;
              e.flightModuleInfo = e.moduleInfo1;
              e.pendingAmount = e.moduleInfo1?.bookingInstallments
                ? this.getPendingAmount(e.moduleInfo1.bookingInstallments)
                : "0";
              e.retailPrice = e.moduleInfo1
                ? this.calculateRetailPrice(e.moduleInfo1, e.extras, null)
                : "0";
              e.totalBookingAmount = e.retailPrice
                ? this.calculateTotalBookingAmount(
                    e.retailPrice,
                    e.extras,
                    e.baggageBoarding,
                    e.transfers
                  )
                : "0";
              e.currencySymbol = e.currency_details?.symbol
                ? e.currency_details?.symbol
                : "";
              e.markupAmount = "0";
              e.providerCost = e.moduleInfo1
                ? this.calculateProviderCost(e.moduleInfo1, null)
                : "0";
              e.flightMarkup = e.moduleInfo1
                ? this.calculateFlightMarkup(e.moduleInfo1)
                : "0";
              e.extrasInitials = this.checkExtrasInitials(e);
            }
            const obj = { ...e };
            return obj;
          });
          this.items.forEach((item, index) => {
            let adult = 0;
            let child = 0;
            let infant = 0;
            // eslint-disable-next-line no-unused-expressions
            item?.bookingTravellers.forEach((cItem) => {
              adult =
                cItem.traveller.passanger_code === "ADT" ? adult + 1 : adult;
              child =
                cItem.traveller.passanger_code === "CHD" ? child + 1 : child;
              infant =
                cItem.traveller.passanger_code === "INF" ? infant + 1 : infant;
            });
            const travellerAgeCount = { adult, child, infant };
            this.items[index].travellerAgeCount = travellerAgeCount;
          });
          this.totalBookings = res.data.count;
          this.startIndex = this.currentPage * this.perPage - this.perPage;
          this.endIndex = this.startIndex + this.perPage;
        }
        payload.bookingRange = this.bookingRange ? this.bookingRange : "";
        payload.travelRange = this.travelRange ? this.travelRange : "";
        payload.wlCode = this.wlCode ? this.wlCode : "all";
        localStorage.setItem("searchData", JSON.stringify(payload));
      } catch (error) {
        console.log(error);
        this.contentLoading = false;
        const errorData = errorResponseHandler(error);
        if (error?.response?.status === 404) {
          this.items = [];
          this.totalBookings = 0;
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        }
      }
    },

    getPendingAmount(data) {
      const pending = data.filter((e) => e.paymentStatus !== 1);
      const pendingAmount = pending.reduce((curr, z) => {
        let current = curr;
        current += Number(z.amount);
        return current;
      }, 0);
      return pendingAmount;
    },

    calculateRetailPrice(moduleInfo, extras, flightModuleInfo) {
      const moduleInfo1 = moduleInfo;
      if (extras?.length) {
        extras.forEach((e) => {
          if (e.type === "Promocode") {
            moduleInfo1.discount = e.amount;
            moduleInfo1.code = e.code;
          }
        });
      }
      const retailPrice = flightModuleInfo
        ? Number(flightModuleInfo.moduleInfo.flight_price) +
          Number(moduleInfo1.sellingPrice)
        : Number(moduleInfo1.sellingPrice);
      return retailPrice;
    },

    calculateTotalBookingAmount(
      retailPrice,
      extras,
      baggageBoarding,
      transfers
    ) {
      let extrasAmount;
      let baggageBoardingAmount;
      let transfersAmount;

      if (extras?.length) {
        // const insurances = extras.filter(e => e.type === "Insurance");
        extrasAmount = extras.reduce((curr, e) => {
          let current = curr;
          current += Number(e.amount);
          return current;
        }, 0);
      }

      if (baggageBoarding?.length) {
        baggageBoardingAmount = baggageBoarding.reduce((curr, e) => {
          let current = curr;
          current += Number(e.price);
          return current;
        }, 0);
      }

      if (transfers?.length) {
        transfersAmount = transfers.reduce((curr, e) => {
          let current = curr;
          current += Number(e.amount);
          return current;
        }, 0);
      }

      let totalBookingAmount = Number(retailPrice);
      if (extrasAmount) {
        totalBookingAmount = totalBookingAmount + Number(extrasAmount);
      }
      if (baggageBoardingAmount) {
        totalBookingAmount = totalBookingAmount + Number(baggageBoardingAmount);
      }
      if (transfersAmount) {
        totalBookingAmount = totalBookingAmount + Number(transfersAmount);
      }
      return totalBookingAmount;
    },

    calculateMarkup(markup, providerCost) {
      const markupAmount = (Number(providerCost) * Number(markup)) / 100;
      return markupAmount.toFixed(2);
    },

    checkExtrasInitials(data) {
      let extrasInitials = [];
      // console.log("data in extras initial", data);
      if (data.baggageBoarding) {
        const baggage = data.baggageBoarding.find((e) => e.comment === "20kg");
        if (baggage) {
          extrasInitials.push("B");
        }
        const priorityBoarding = data.baggageBoarding.find(
          (e) => e.comment === "Priority"
        );
        if (priorityBoarding) {
          extrasInitials.push("P");
        }
      }
      if (data.extras) {
        const insurance = data.extras.find((e) => e.type === "Insurance");
        if (insurance) {
          extrasInitials.push("I");
        }
      }
      if (data.transfers) {
        extrasInitials.push("T");
      }
      return extrasInitials;
    },

    calculateFlightMarkup(flightModuleInfo) {
      const flightMarkup = flightModuleInfo
        ? (
            Number(flightModuleInfo.moduleInfo.flight_price) -
            Number(flightModuleInfo.moduleInfo.base_fare)
          ).toFixed(2)
        : "0";
      return flightMarkup;
    },

    calculateProviderCost(moduleInfo1, flightModuleInfo) {
      const providerCost = flightModuleInfo
        ? Number(moduleInfo1?.providerCost) +
          Number(flightModuleInfo.moduleInfo.base_fare)
        : Number(moduleInfo1?.providerCost);
      return providerCost.toFixed(2);
    },

    transferInitialVariant(data) {
      if (data.transfers) {
        return data.transfers.find((e) => e.status === "Cancelled")
          ? "light-secondary"
          : data.transfers.find((e) => e.status === "Pending")
          ? "light-warning"
          : "light-success";
      }
      return "";
    },

    openStatusModal(status, id, supplierId, cancellationReason, flag) {
      this.isLoaded = true;
      switch (status) {
        case "Pending":
          status = 0;
          break;
        case "Confirmed":
          status = 1;
          break;
        case "Failed":
          status = 2;
          break;
        case "Cancelled":
          status = 3;
          break;
      }
      this.statusDetails = {
        status,
        id,
        supplierId,
        cancellationReason,
        flag,
      };

      this.$refs.formModal.show();
    },

    async changeBookingStatus() {
      const { id, status, cancellationReason, supplierId } = this.statusDetails;
      try {
        const res = await bookingService.changeBookingStatus(
          {
            booking_quote_id: id,
            status,
            supplier_booking_id: supplierId,
            reason: cancellationReason,
          },
          this.wlCode
        );
        if (resConditionCheck(res.status) && res.data.message) {
          this.getBookingListing();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
        }
      } catch (error) {
        const errorData = errorResponseHandler(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      }
    },

    async saveRefund(id, refund, wl_code) {
      try {
        const res = await bookingService.addRefund(
          {
            refund_amount: refund,
            booking_quote_id: id,
          },
          wl_code
        );
        if (resConditionCheck(res.status) && res.data.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
        }
      } catch (error) {
        const errorData = errorResponseHandler(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      }
    },

    // openRefundModal(index) {
    //   this.index = index
    //   this.$refs.refundModal.show()
    // },

    // test(bvModalEvt, refundForm) {
    // },

    navigateToBookingDetail(id, mode, wlcode) {
      if (mode === 2) {
        this.$router.push({
          name: "new-booking-details",
          params: { id },
        });
      } else {
        this.$router.push({
          name: "booking-details",
          params: { id },
          query: {
            page: this.currentPage,
            rows: this.perPage,
            wl_code: wlcode,
          },
        });
      }
    },

    createNewBooking() {
      this.$router.push(`/booking/manual-booking/`);
    },

    disabledRange(date) {
      return date > new Date();
    },

    // bookingIdValidate(e) {
    //   if (!/[A-Za-z0-9_-]+/) {

    //   }
    // }
    getExportData() {
      const WLCODE =
        localStorage.getItem("signinWLAdmin") &&
        localStorage.getItem("whitelabel_code")
          ? localStorage.getItem("whitelabel_code")
          : this.wl_code !== 1
          ? this.wl_code
          : this.wlCode;
      this.exportContentLoading = true;
      const payload = {
        no_of_result: 0,
        page: 1,
        order: this.isSortDirDesc === true ? "DESC" : "ASC",
        order_by: this.sortBy,
        status: this.status,
        payment_via: this.payment_via,
        customer_type: this.customer_type,
        module_id: this.module_id,
        booking_id: this.booking_id,
        email: this.email,
        booking_start_date: this.booking_start_date
          ? this.booking_start_date
          : "",
        booking_end_date: this.booking_end_date ? this.booking_end_date : "",
        travel_start_date: this.travel_start_date,
        travel_end_date: this.travel_end_date,
        booking_mode: this.booking_mode,
        all_whitelabel: this.wlCode === "all" ? true : false,
      };
      bookingService
        .getBookingListing(payload, WLCODE)
        .then((res) => {
          if (resConditionCheck(res.status) && res.data.data) {
            this.exportResults = res.data.data.map((e) => {
              this.exportContentLoading = false;
              if (
                e.bookings[0]?.moduleId === 1 ||
                e.bookings[0]?.moduleId === 3
              ) {
                e.moduleInfo1 = e.bookings.find(
                  (z) => z.moduleInfo.type === "Hotel"
                );
                e.flightModuleInfo = e.bookings.find(
                  (z) => z.moduleInfo.type === "Flight"
                );
                e.pendingAmount = e.moduleInfo1?.bookingInstallments
                  ? this.getPendingAmount(e.moduleInfo1.bookingInstallments)
                  : "0";
                e.retailPrice = e.moduleInfo1
                  ? this.calculateRetailPrice(
                      e.moduleInfo1,
                      e.extras,
                      e.flightModuleInfo
                    )
                  : "0";
                e.totalBookingAmount = e.retailPrice
                  ? this.calculateTotalBookingAmount(
                      e.retailPrice,
                      e.extras,
                      e.baggageBoarding,
                      e.transfers
                    )
                  : "0";
                e.currencySymbol = e.currency_details?.symbol
                  ? e.currency_details?.symbol
                  : "";
                e.markupAmount =
                  e.moduleInfo1?.moduleInfo && e.moduleInfo1?.providerCost
                    ? this.calculateMarkup(
                        e.moduleInfo1?.moduleInfo?.rooms?.[0]?.markup,
                        e.moduleInfo1?.providerCost
                      )
                    : "0";
                e.providerCost = e.moduleInfo1
                  ? this.calculateProviderCost(
                      e.moduleInfo1,
                      e.flightModuleInfo
                    )
                  : "0";
                e.flightMarkup = e.flightModuleInfo
                  ? this.calculateFlightMarkup(e.flightModuleInfo)
                  : "0";
              } else if (e.bookings[0]?.moduleId === 2) {
                e.moduleInfo1 = e.bookings.find(
                  (z) => z.moduleInfo.type === "Flight"
                );
                e.flightModuleInfo = e.moduleInfo1;
                e.pendingAmount = e.moduleInfo1?.bookingInstallments
                  ? this.getPendingAmount(e.moduleInfo1.bookingInstallments)
                  : "0";
                e.retailPrice = e.moduleInfo1
                  ? this.calculateRetailPrice(e.moduleInfo1, e.extras, null)
                  : "0";
                e.totalBookingAmount = e.retailPrice
                  ? this.calculateTotalBookingAmount(
                      e.retailPrice,
                      e.extras,
                      e.baggageBoarding,
                      e.transfers
                    )
                  : "0";
                e.currencySymbol = e.currency_details?.symbol
                  ? e.currency_details?.symbol
                  : "";
                e.markupAmount = "0";
                e.providerCost = e.moduleInfo1
                  ? this.calculateProviderCost(e.moduleInfo1, null)
                  : "0";
                e.flightMarkup = e.moduleInfo1
                  ? this.calculateFlightMarkup(e.moduleInfo1)
                  : "0";
              }
              let adult = 0;
              let child = 0;
              let infant = 0;
              e?.bookingTravellers.forEach((cItem) => {
                adult =
                  cItem.traveller.passanger_code === "ADT" ? adult + 1 : adult;
                child =
                  cItem.traveller.passanger_code === "CHD" ? child + 1 : child;
                infant =
                  cItem.traveller.passanger_code === "INF"
                    ? infant + 1
                    : infant;
              });
              return {
                Booking_Id:
                  e.moduleInfo1 && e.moduleInfo1?.localBookingId
                    ? e.moduleInfo1?.localBookingId
                    : "-",
                Supplier_Id:
                  e.moduleInfo1 && e.moduleInfo1?.supplierBookingId
                    ? e.moduleInfo1?.supplierBookingId
                    : "-",
                Creation_Date:
                  e.moduleInfo1 && e.moduleInfo1?.bookingDateTime
                    ? this.moment(e.moduleInfo1?.bookingDateTime).format(
                        "DD-MM-YYYY"
                      )
                    : "-",
                Status: e.bookingStatus ? e.bookingStatus : "-",
                Type:
                  e.moduleInfo1 && e.moduleInfo1?.moduleId
                    ? this.TripType(e.moduleInfo1?.moduleId)
                    : "-",
                Remark:
                  e.remarks && e.remarks?.remarks ? e.remarks?.remarks : "-",
                Contact_Person: e.user_details
                  ? `${e.user_details.firstName} ${e.user_details.lastName}`
                  : "-",
                Email: e.user_details ? e.user_details.email : "-",
                White_Label:
                  e.wlCode && this.wl_code == 1
                    ? this.getWhiteLabelName(e.wlCode)
                    : "-",
                Agency: e.moduleInfo1?.agency
                  ? this.AgencyStatus(e.moduleInfo1?.agency)
                  : "-",
                Checkin_Checkout:
                  e.moduleInfo1 && e.moduleInfo1.searchCriteria
                    ? `${this.moment(
                        e.moduleInfo1.searchCriteria.checkin_date
                      ).format("DD-MM-YYYY")} - ${this.moment(
                        e.moduleInfo1.searchCriteria.checkout_date
                      ).format("DD-MM-YYYY")}`
                    : "-",
                Origin:
                  e.flightModuleInfo && e.flightModuleInfo?.searchCriteria
                    ? e.flightModuleInfo.searchCriteria.origin?.value
                    : "-",
                Destination:
                  e.moduleInfo1 &&
                  e.moduleInfo1.moduleId !== 2 &&
                  e.moduleInfo1?.searchCriteria
                    ? e.moduleInfo1.searchCriteria.value
                    : e.moduleInfo1.searchCriteria.destination.value,
                Travellers: e.bookingTravellers
                  ? e.bookingTravellers.length
                  : "0",
                Adult: adult ? adult : "0",
                Child: child ? child : "0",
                Infant: infant ? infant : "0",
                Currency: e.currency_details ? e.currency_details.code : "-",
                Payment_Type:
                  e.moduleInfo1 && e.moduleInfo1?.paymentVia
                    ? this.PaymentType(e.moduleInfo1?.paymentVia)
                    : "-",
                Retail_Price: e.retailPrice
                  ? `${e.retailPrice} ${e.currencySymbol}`
                  : "-",
                Provider_Cost: e.providerCost
                  ? `${e.providerCost} ${e.currencySymbol}`
                  : "-",
                Hotel_Markup: e.markupAmount
                  ? `${e.markupAmount} ${e.currencySymbol}`
                  : "-",
                Flight_Markup: e.flightMarkup
                  ? `${e.flightMarkup} ${e.currencySymbol}`
                  : "-",
                Discount:
                  e.moduleInfo1 && e.moduleInfo1.discount
                    ? `${Math.abs(e.moduleInfo1.discount)} ${e.currencySymbol}`
                    : "0",
                Total: e.totalBookingAmount
                  ? Number(e.totalBookingAmount)
                  : "0",
                First_Installment:
                  e.moduleInfo1 &&
                  e.moduleInfo1.bookingInstallments &&
                  e.moduleInfo1.bookingInstallments[0]
                    ? `${e.moduleInfo1.bookingInstallments[0].amount} ${e.currencySymbol}`
                    : "-",
                Second_Installment:
                  e.moduleInfo1 &&
                  e.moduleInfo1.bookingInstallments &&
                  e.moduleInfo1.bookingInstallments[1]
                    ? `${e.moduleInfo1.bookingInstallments[1].amount} ${e.currencySymbol}`
                    : "-",
                Second_Installment_Deadline:
                  e.moduleInfo1 &&
                  e.moduleInfo1.bookingInstallments &&
                  e.moduleInfo1.bookingInstallments[1]
                    ? `${this.moment(
                        e.moduleInfo1.bookingInstallments[1].installmentDate
                      ).format("DD-MM-YYYY")}`
                    : "-",

                Second_Installment_Payment_Date:
                  e.moduleInfo1 &&
                  e.moduleInfo1.bookingInstallments &&
                  e.moduleInfo1.bookingInstallments[1]
                    ? `${this.moment(
                        e.moduleInfo1.bookingInstallments[1].paymentDate
                      ).format("DD-MM-YYYY")}`
                    : "-",
                Pending: e.pendingAmount
                  ? `${e.pendingAmount} ${e.currencySymbol}`
                  : "0",
                Refund: e.refund ? e.refund : "0",
                Billing_Name:
                  e.billingInfo && e.billingInfo.billing_name
                    ? e.billingInfo.billing_name
                    : "-",
                Postal_Code:
                  e.billingInfo && e.billingInfo.postal_code
                    ? e.billingInfo.postal_code
                    : "-",
                Country:
                  e.billingInfo && e.billingInfo.country
                    ? e.billingInfo.country
                    : "-",
                City:
                  e.billingInfo && e.billingInfo.city
                    ? e.billingInfo.city
                    : "-",
                Address:
                  e.billingInfo && e.billingInfo.address1
                    ? `${e.billingInfo.address1} ${
                        e.billingInfo.address2 ? e.billingInfo.address2 : ""
                      }`
                    : "",
                VAT:
                  e.billingInfo && e.billingInfo.vat_number
                    ? e.billingInfo.vat_number
                    : "-",
                Remarks: e.remarks && e.remarks?.notes ? e.remarks.notes : "-",
              };
            });
          }
        })
        .catch((error) => {
          this.exportContentLoading = false;
          console.log(error);
          this.exportResults = this.items;
        });
    },

    async getWhitelabelList() {
      this.isLoaded = false;
      const payload = {
        no_of_result: 0,
        page: 1,
        order: "ASC",
        order_by: "name",
      };
      await whitelabelService
        .getWhitelableList(payload)
        .then((res) => {
          if (resConditionCheck(res.status) && res.data.data) {
            this.isLoaded = true;
            this.whitelabelList = res.data.data.map((item) => {
              // if(!item.isDeleted) {
              return {
                label: item.name,
                value: item.id,
              };
              // }
            });
            this.whitelabelList.push({ label: "Beontrips", value: 1 });
            this.whitelabelList.unshift({ label: "All", value: "all" });
          }
        })
        .catch((error) => {
          console.log("Error", error);
          const errorData = errorResponseHandler(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        });
    },
    getWhiteLabelName(wlCode) {
      let label = this.whitelabelList.find((wl) => wl.value === wlCode)?.label;
      return label ? label : "Beontrips";
    },
    showExportModal() {
      setTimeout(() => {
        this.progressExport = 25;
      }, 200);
      this.getExportData();
      setTimeout(() => {
        this.progressExport = 50;
      }, 500);
      setTimeout(() => {
        this.progressExport = 75;
      }, 700);
      setTimeout(() => {
        this.progressExport = 100;
      }, 1000);
      this.$refs["export-modal"].show();
    },
    hideExportModal() {
      this.progressExport = 0;
      setTimeout(() => {
        this.progressExport = 10;
      }, 500);
      this.$refs["export-modal"].hide();
    },

    async getWhitelabelDetails() {
      let wlCode = axios.defaults.headers.wl_code;
      await whitelabelService
        .getWhitelabelDetail(wlCode)
        .then((res) => {
          if (resConditionCheck(res.status) && res.data.data) {
            this.whitelabelDetail = res.data.data;
            this.whitelabelDetail.settingsData.modules.map((e) => {
              if (e.isActive === false) {
                let find = this.tripTypeFilter2.findIndex((i) => {
                  return e.name === i.label;
                });
                find > -1 ? this.tripTypeFilter2.splice(find, 1) : "";
              }
            });
          }
        })
        .catch((error) => {
          const errorData = errorResponseHandler(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        });
    },

    async applyFilter() {
      this.currentPage = 1;
      const searchData = JSON.parse(localStorage.getItem("searchData"));
      if (searchData) {
        searchData.page = 1; // updating the status property
        localStorage.setItem("searchData", JSON.stringify(searchData)); // updating the searchData in localStorage
      }
      await this.getBookingListing();
    },

    async resetFilter() {
      this.status = "";
      this.payment_via = "";
      this.customer_type = "";
      this.module_id = "";
      this.booking_id = "";
      this.email = "";
      this.booking_start_date = "";
      this.booking_end_date = "";
      this.travel_start_date = "";
      this.travel_end_date = "";
      this.booking_mode = "";
      this.module_supplier_id = "";
      this.wlCode = "all";
      this.currentPage = 1;
      this.bookingRange = "";
      this.travelRange = "";
      this.$router
        .push({
          name: "booking-list",
          query: { page: 1, rows: 10, wl_code: "all" },
        })
        .catch(() => {});
      localStorage.removeItem("searchData");
      await this.getBookingListing();
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
/** No booking fixed label css
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
 */
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
